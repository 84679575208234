<template>
	<b-container v-if="order">
		<b-row>
			<b-col>
				<h3 class="mt-3">{{ '訂單資料' }}</h3>
			</b-col>
			<b-col class="mt-3 float-right" md="12" lg="4">
				<b-button variant="danger" block @click="onDelete">刪除</b-button>
			</b-col>
		</b-row>

		<b-form class="mt-3" @submit.stop.prevent="onSubmit">
			<h5>內容</h5>
			<b-form-group label="日期">
				<b-form-input v-model="date" disabled></b-form-input>
			</b-form-group>
			<b-form-group label="店名">
				<b-form-input v-model="order.merchantName" disabled></b-form-input>
			</b-form-group>
			<b-form-group label="分店">
				<b-form-input v-model="order.shop.name" disabled></b-form-input>
			</b-form-group>
			<b-form-group label="分店地址">
				<b-form-input v-model="order.shop.address" disabled></b-form-input>
			</b-form-group>
			<b-form-group label="來源">
				<b-form-input v-model="order.source" disabled></b-form-input>
			</b-form-group>
			<b-form-group label="方式">
				<b-form-input v-model="orderMethod" disabled></b-form-input>
			</b-form-group>
			<b-form-group v-if="order.customer" label="客戶名稱">
				<b-form-input v-model="order.customer.name" disabled></b-form-input>
			</b-form-group>
			<b-form-group v-if="order.customer" label="客戶電話號碼">
				<b-form-input v-model="order.customer.phone" disabled></b-form-input>
			</b-form-group>
			<b-form-group v-if="order.customer && order.customer.address" label="客戶地址">
				<b-form-input v-model="order.customer.address" disabled></b-form-input>
			</b-form-group>
			<b-form-group label="付款方式">
				<b-form-input v-model="orderPaymentMethod" disabled></b-form-input>
			</b-form-group>
			<b-form-group label="商品小計">
				<b-input-group prepend="$">
					<b-form-input v-model="order.productTotal" disabled></b-form-input>
				</b-input-group>
			</b-form-group>
			<b-form-group label="折扣小計">
				<b-input-group prepend="$">
					<b-form-input v-model="order.discountTotal" disabled></b-form-input>
				</b-input-group>
			</b-form-group>
			<b-form-group label="總計">
				<b-input-group prepend="$">
					<b-form-input v-model="order.grandTotal" disabled></b-form-input>
				</b-input-group>
			</b-form-group>
			<b-form-group label="狀態">
				<b-form-select v-model="order.status" :options="orderStatusOption"></b-form-select>
			</b-form-group>

			<b-table :fields="productFields" :items="order.products" hover responsive></b-table>

			<b-table v-if="order.schema && order.schema >= 2" :fields="discountFields" :items="order.discounts" hover responsive></b-table>

			<b-row>
				<b-col>
					<b-form-group>
						<b-button block @click="$router.go(-1)">返回</b-button>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group>
						<b-button type="submit" variant="primary" block>儲存</b-button>
					</b-form-group>
				</b-col>
			</b-row>
		</b-form>
	</b-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
	data() {
		return {
			order: null,
			productFields: [
				{
					key: 'name',
					label: '產品',
					sortable: true,
				},
				{
					key: 'count',
					label: '數量',
					sortable: true,
				},
				{
					key: 'total',
					label: '總計',
					sortable: true,
					formatter: (value) => {
						return `$${value}`
					},
				},
			],
			discountFields: [
				{
					key: 'name',
					label: '折扣',
					sortable: true,
				},
			],
			orderStatusOption: [
				{ value: 'PENDING', text: '待接單' },
				{ value: 'RECEIVED', text: '已接單' },
				{ value: 'PENDING_PAYMENT', text: '待付款' },
				{ value: 'PACKED', text: '待取餐' },
				{ value: 'COMPLETED', text: '已完成' },
				{ value: 'VOID', text: '已取消' },
			],
		}
	},

	async created() {
		this.order = await this.$store.dispatch('getDocument', { col: 'orders', id: this.$route.params.id })
		if (!this.order) return this.$router.push('/orders')
	},

	computed: {
		...mapState({
			orders: (state) => state.orders,
		}),

		date() {
			return moment.unix(this.order.createdAt).format('YYYY-MM-DD')
		},

		orderMethod() {
			switch (this.order.method) {
				case 'PICKUP':
					return '自取'
				case 'DELIVERY':
					return '外送'
			}
			return ''
		},

		orderPaymentMethod() {
			switch (this.order.payment.method) {
				case 'CASH':
					return '現金'
				case 'OCTOPUS':
					return '八達通'
				case 'VISA':
					return 'Visa'
				case 'MASTER':
					return 'Mastercard'
				case 'UPOP':
					return '銀聯'
				case 'AE':
					return 'American Express'
				case 'JCB':
					return 'JCB'
				case 'PPS':
					return 'PPS繳費靈'
				case 'WECHATPAY':
					return 'WeChat Pay'
				case 'ALIPAY':
					return '支付寶'
				case 'PAYME':
					return 'PayMe'
				case 'TAPNGO':
					return 'Tap & Go'
				case 'FPS':
					return '轉數快'
				case 'RECON_VISA':
					return 'RECON Visa'
				case 'RECON_MASTER':
					return 'RECON Mastercard'
				case 'RECON_UPOP':
					return 'RECON 銀聯'
				case 'ON_THE_HOUSE':
					return 'On The House'
				case 'DELIVEROO':
					return '戶戶送'
				case 'FOODPANDA':
					return 'foodpanda'
			}
			return ''
		},
	},
	methods: {
		async onDelete() {
			const res = await this.$store.dispatch('deleteDocument', { col: 'orders', id: this.order.id })
			this.$root.$bvToast.toast(`刪除${res ? '成功' : '失敗'}`, { title: res ? '成功' : '失敗', variant: res ? 'success' : 'danger' })
			return this.$router.push('/orders')
		},
		async onSubmit() {
			this.order.merchantId = localStorage.merchantId

			let res = await this.$store.dispatch('updateDocument', { col: 'orders', doc: this.order })
			this.$root.$bvToast.toast(`${'更新'}${res ? '成功' : '失敗'}`, { title: res ? '成功' : '失敗', variant: res ? 'success' : 'danger' })
			return this.$router.push('/orders')
		},
	},
}
</script>
